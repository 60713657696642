import React from 'react';
import SearchInput from '../Inputs/SearchInput';
import { useTimeFilters } from '../Sharing/TimeFilters';
import SlideWrapper from '../Sharing/SlideWrapper';

export default function FilterForm({
  handleDatePickerChange,
  handleChange,
  values,
}) {
  const timeFilters = useTimeFilters({
    handleDatePickerChange,
    handleChange,
    values,
  });
  return (
    <form
      className='container-fluid p-0 justify-content-start d-flex gap-1 gestion_filter_form'
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <SlideWrapper>
        <div style={{ maxWidth: '128px' }} className='container-fluid p-0 m-0'>
          <SearchInput
            onChange={(e) => {
              handleChange({
                name: e.target.name,
                value: e.target.value,
              });
            }}
            value={values.search}
            label='Buscar'
            id='search'
            name='search'
            type='search'
            valid={true}
            rounded={true}
          />
        </div>
        {timeFilters}
      </SlideWrapper>

      <button type='submit' className='d-none'></button>
    </form>
  );
}
