import React, { useState, useEffect } from "react";
import Header from "./Header";
import Rows from "./Rows";

const Container = React.memo(
  ({
    columns,
    data,
    row_classname,
    row_styles,
    header_styles,
    header_classname,
    children,
    setSortConfig,
    sortConfig,
  }) => {
    const handleSort = (key) => {
      setSortConfig({
        orderBy: key,
        order: sortConfig.order === "asc" ? "desc" : "asc",
      });
    };

    return (
      <div className="rounded shadow d-flex flex-column">
        <div
          id="table_body_scroll"
          style={{ overflowY: "hidden" }}
          className="table-responsive"
        >
          <table className="table mb-0">
            <Header
              sortConfig={sortConfig}
              columns={columns}
              handleSort={handleSort}
              {...(header_styles && { header_styles: header_styles })}
              {...(header_classname && { header_classname: header_classname })}
            />
            <tbody>
              {data.length === 0 ? (
                <div
                  style={{ padding: "30px" }}
                  className="container d-flex justify-content-center"
                >
                  <p style={{ fontSize: "16px", opacity: ".8" }}>
                    No hay contenido que mostrar en esta tabla
                  </p>
                </div>
              ) : (
                data.map((row, index) => (
                  <Rows
                    key={`table-row-${index}`}
                    columns={columns}
                    row={row}
                    index={index}
                    {...(row_classname && { row_classname: row_classname })}
                    {...(row_styles && { row_styles: row_styles })}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
        {children}
      </div>
    );
  }
);

export default Container;
