import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useCallback,
  useRef,
} from "react";
import CopyCell from "./CopyCell";
import FilterForm from "./FilterForm";
import Table from "../Table/Container";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import dayjs from "dayjs";
import ExportData from "./ExportData";
import Details from "./Details";
import {
  useLoaderReducer,
  useErrorReducer,
  useSucceedReducer,
  useValuesReducer,
  validarFecha,
  getDefaultInitialDateValues,
  TIME_ALERT,
} from "../../services/data";
import { Permisos } from "../../services/permisos";
import { getDataTransactions, downloadTransactions } from "../../services/api";
import utc from "dayjs/plugin/utc";
import usePaginationBack from "../Table/usePaginationBack";
import Pagination from "../Table/Pagination";
import LimpiarFiltros from "../Sharing/LimpiarFiltros";

dayjs.extend(utc);

export const TransaccionContext = createContext();

const formatHour = (hour) => {
  if (hour < 0 || hour > 23) {
    //throw new Error("Hour must be between 0 and 23");
    return "23:59";
  }
  const formattedHour = hour.toString().padStart(2, "0");
  return `${formattedHour}:00`;
};

const INITIAL_VALUES = {
  search: "",
  ...getDefaultInitialDateValues(),
  photo_quality: [],
  response_time: [],
  first_mount: true,
};

const conditionalColumn = [
  {
    name: "Detalles",
    custom_cell: (data) => <Details data={data} />,
    className: "table_header_cell",
    style: {
      minWidth: "120px",
      maxWidth: "120px",
    },
    cell_styles: {
      minWidth: "120px",
      maxWidth: "120px",
    },
    cell_classname: "table_row_cell",
  },
];

export default function Body() {
  const { errorValues, handleError } = useErrorReducer();
  const { loaderValues, handleLoader } = useLoaderReducer();
  const { succeedValues, handleSucceed } = useSucceedReducer();
  const {
    createHandleChange,
    createHandleDatePickerChange,
    daysController,
    values,
  } = useValuesReducer(INITIAL_VALUES);
  const [tableData, setTableData] = useState([]);
  const [scoreLimits, setScoreLimits] = useState({
    min: 10,
    max: 100,
  });
  const [responseTimeLimits, setResponseTimeLimits] = useState({
    min: 10,
    max: 100,
  });
  const dataTable = useMemo(() => tableData, [tableData]);
  const abortControllerRef = useRef(null);
  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();
  const [orderConfig, setOrderConfig] = useState({
    order: "asc",
    orderBy: "idTransaccion",
  });

  // Handlers

  const handleChange = createHandleChange(handleResetPagination);

  const handleDatePickerChange = createHandleDatePickerChange(
    handleResetPagination
  );

  useEffect(() => {
    if (values.days !== "") {
      daysController[values.days]({
        start_date: "start_date",
        start_hours: "start_hours",
        end_date: "end_date",
        end_hours: "end_hours",
      });
    }
  }, [daysController, values.days]);

  const handleGetTransacions = useCallback(async () => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      handleLoader("increment");

      const response = await getDataTransactions({
        page: pagination.page,
        ...values,
        signal: abortControllerRef.current.signal,
        OrderAscending: orderConfig?.order,
        OrderBy: orderConfig?.orderBy,
      });

      if (response.name === "AbortError") {
        console.log("Request canceled");
        return;
      }

      if (!response.success) {
        handleError(response.message);
        return;
      }

      const {
        list,
        max_tiempo_respuesta,
        min_tiempo_respuesta,
        ...restPagination
      } = response.data;

      setTableData(list);
      handleValidateTotalPages({
        totalPages: restPagination.totalPages,
        page: restPagination.page,
        totalElements: restPagination.totalElements,
      });
      setResponseTimeLimits({
        max: max_tiempo_respuesta,
        min: min_tiempo_respuesta,
      });
    } catch (error) {
      console.log("Error en el handler");
      console.error(error);

      if (error.name === "AbortError") {
        console.log("Request canceled");
        return;
      }

      setTableData([]);
      handleError("Lo sentimos, ocurrió un error");
    } finally {
      handleLoader("decrement");
    }
  }, [handleLoader, pagination.page, values, orderConfig]);

  const availableDownloads = {
    excel: {
      params: "&formato=xls&tipo=transacciones",
      name: "Transacciones.xls",
    },
    csv: {
      params: "&formato=csv&tipo=transacciones",
      name: "Transacciones.csv",
    },
    pdf: {
      params: "&formato=pdf&tipo=transacciones",
      name: "Transacciones.pdf",
    },
  };

  const handleDownloadData = useCallback(
    async (selected, handleModal) => {
      try {
        handleLoader("increment");
        const response = await downloadTransactions({
          ...values,
          download: availableDownloads[selected],
          signal: abortControllerRef.current.signal,
          OrderAscending: orderConfig?.order,
          OrderBy: orderConfig?.orderBy,
        });
        if (!response.success) {
          handleLoader("decrement");
          handleError(response.message);
          return;
        }
        handleLoader("decrement");
        handleSucceed("La exportación de datos se ha realizado con éxito");
        setTimeout(() => {
          handleModal();
        }, TIME_ALERT);
      } catch (error) {
        console.log(error);
        handleLoader("decrement");
        handleError("Lo sentimos ocurrió un error");
        return;
      }
    },
    [values]
  );

  useEffect(() => {
    if (validarFecha(values, handleError)) handleGetTransacions();
    else setTableData([]);
  }, [orderConfig, pagination.page, values]);

  const handleOrderByColumns = useCallback(({ sort_key, order }) => {
    setOrderConfig({ order, orderBy: sort_key });
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "ID transacción",
        custom_cell: (data) =>
          data?.idTransaccion ? <CopyCell text={data.idTransaccion} /> : "",
        selector: (data) => data?.idTransaccion ?? "",
        key_name: "idTransaccion",
        className: "table_header_cell_management",
        service: handleOrderByColumns,
        style: {
          minWidth: "160px",
          maxWidth: "160px",
        },
        cell_styles: {
          minWidth: "160px",
          maxWidth: "160px",
        },
        cell_classname: "table_row_cell_management",
      },
      {
        name: "Fecha/Hora",
        selector: ({ fecha_alta }) =>
          dayjs
            .utc(fecha_alta)
            .utcOffset(-6)
            .format("DD/MM/YYYY HH:mm:ss [(GMT-6)]"),
        key_name: "fecha_alta",
        className: "table_header_cell",
        service: handleOrderByColumns,
        style: {
          minWidth: "170px",
          maxWidth: "170px",
        },
        cell_styles: {
          minWidth: "170px",
          maxWidth: "170px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Tiempo de respuesta",
        selector: (data) =>
          `${
            data?.tiempos_servicios?.instruccion_validar_embeddings / 1000 ?? ""
          } seg`,
        key_name: "tiempos_servicios",
        className: "table_header_cell",
        service: handleOrderByColumns,
        style: {
          minWidth: "150px",
          maxWidth: "150px",
        },
        cell_styles: {
          minWidth: "150px",
          maxWidth: "150px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Hash del CURP",
        custom_cell: (data) =>
          data.curp_hash ? <CopyCell text={data.curp_hash} /> : "N/A",
        selector: (data) => data.curp_hash ?? "N/A",
        key_name: "curp_hash",
        className: "table_header_cell",
        service: handleOrderByColumns,
        style: {
          minWidth: "150px",
          maxWidth: "150px",
        },
        cell_styles: {
          minWidth: "150px",
          maxWidth: "150px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Índice calidad de fotografía",
        selector: (data) =>
          data.foto_calidad_promedio
            ? Math.trunc(parseFloat(data.foto_calidad_promedio) * 100) / 100
            : "N/A",
        key_name: "foto_calidad_promedio",
        className: `table_header_cell`,
        service: handleOrderByColumns,
        style: {
          minWidth: "160px",
          maxWidth: "160px",
        },
        cell_styles: {
          minWidth: "160px",
          maxWidth: "160px",
        },
        cell_classname: "table_row_cell",
      },
      {
        name: "Validación",
        selector: (data) => data.validacion ?? "",
        key_name: "validation",
        className: "table_header_cell",
        service: handleOrderByColumns,
        style: {
          minWidth: "110px",
          miaxWidth: "110px",
        },
        cell_styles: {
          minWidth: "110px",
          miaxWidth: "110px",
        },
        cell_classname: "table_row_cell",
      },
    ],
    [handleOrderByColumns]
  );

  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={[
          ...columns,
          ...(Permisos.verificarPermiso("Transacciones", "Ver detalle")
            ? conditionalColumn
            : []),
        ]}
        data={dataTable}
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
        header_classname="table_header"
        header_styles={{
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [
    columns,
    dataTable,
    handleNextPage,
    handlePreviousPage,
    handleSelectedPage,
    orderConfig,
    pagination,
  ]);

  return (
    <TransaccionContext.Provider
      value={{ dataTable, handleError, handleDownloadData }}
    >
      <div className="container-fluid d-flex flex-column p-0">
        <div
          style={{ marginBottom: "11px" }}
          className="d-flex justifu-content-between flex-wrap-reverse flex-lg-nowrap justify-content-end mt-3"
        >
          {Permisos.verificarPermiso("Transacciones", "Buscar") && (
            <FilterForm
              {...{
                handleDatePickerChange,
                handleChange,
                values,
                scoreLimits,
                responseTimeLimits,
              }}
            />
          )}
          {Permisos.verificarPermiso("Transacciones", "Exportar") && (
            <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap">
              <LimpiarFiltros
                handleChange={handleChange}
                INITIAL_VALUES={INITIAL_VALUES}
              />
              <ExportData />
            </div>
          )}
        </div>
        {memoizedUserTable}
      </div>
      {loaderValues.loading > 0 && <Loader />}
      {errorValues.timeLeft > 0 && (
        <AlertaGeneral type={"error"}>{errorValues.currentError}</AlertaGeneral>
      )}
      {succeedValues.timeLeft > 0 && (
        <AlertaGeneral type={"success"}>
          {succeedValues.currentSucceed}
        </AlertaGeneral>
      )}
    </TransaccionContext.Provider>
  );
}
