import { UserContext } from "./Body";
import Select from "./Select";
import {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { updateStatusUserById } from "../../services/api";
import DeleteUser from "./DeleteUser";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Loader from "../Loader/Loader";
import ActivateUser from "./ActivateUser";
import BloquearUsuario from "./BloquearUsuario";
import { TIME_ALERT } from "../../services/data";

const useUserContext = () => useContext(UserContext);

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

export const handleActivate = async ({
  id,
  loading,
  error,
  error_message,
  success,
  success_message,
  service,
  handleRefreshData,
}) => {
  try {
    loading();
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const response = await service(id);
    if (!response.success) {
      loading();
      error();
      error_message(response.message || "Error inesperado, intente nuevamente");
      setTimeout(() => {
        error();
        error_message("");
      }, TIME_ALERT);
      console.error("Error request");
      return;
    }
    loading();
    success();
    success_message(response.message);
    handleRefreshData();
    setTimeout(() => {
      success();
      success_message("");
    }, TIME_ALERT);
  } catch (err) {
    loading();
    error();
    if (error.message === "NETWORK_CONNECTION") {
      error_message(
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
    } else {
      error_message("Lo sentimos ocurrió un error, intente más tarde");
    }
    setTimeout(() => {
      error();
      error_message("");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  }
};
const useUserTableStatus = ({ status, id, setDataTable, index }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [activateUser, setActivateUser] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [value, setValue] = useState({});
  const deleteButtonRef = useRef(null);
  const { statusOptions, handleRefreshData } = useUserContext();
  const [statusActual, setStatusActual] = useState(status);
  const [statusOptionsLimited, setStatusOptionsLimited] = useState([]);
  const [openBlockUser, setOpenBlockUser] = useState(false);
  const [fromBlockActivate, setFromBlockActivater] = useState(false);

  useEffect(() => {
    setValue(statusOptions.filter((value) => value.id === status)[0]?.id ?? "");
  }, []);

  useEffect(() => {
    if (statusActual === 2)
      setStatusOptionsLimited(statusOptions.filter((value) => value.id !== 0));
    else if (statusActual === 0)
      setStatusOptionsLimited(statusOptions.filter((value) => value.id !== 2));
    else setStatusOptionsLimited(statusOptions);
  }, [statusOptions, statusActual]);

  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleError = () => {
    setError((prevState) => !prevState);
  };

  const handleErrorMessage = (msj) => {
    setErrorMessage(msj);
  };

  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleSuccessMessage = (msj) => {
    setSuccessMessage(msj);
  };

  const handleBlockUser = async () => {
    if (statusActual === 2) return;
    else setOpenBlockUser(true);
  };
  const closeBlockUserModal = () => {
    setOpenBlockUser(false);
  };
  const bloquearUsuario = async () => {
    try {
      handleLoading();
      if (!navigator.onLine) {
        throw new Error("NETWORK_CONNECTION");
      }
      let response = await updateStatusUserById(id, 2);
      if (!response.success) {
        handleError();
        if (error.message === "NETWORK_CONNECTION") {
          setErrorMessage(
            "No hay conexión a Internet. Por favor, verifica tu conexión."
          );
        } else if (response.message) {
          setErrorMessage(response.message);
        } else {
          handleErrorMessage("Lo sentimos ocurrió un error, intente más tarde");
        }
        setValue(
          statusOptions.filter((value) => value.id === statusActual)[0].id
        );
        setTimeout(() => {
          handleError();
          handleErrorMessage("");
        }, TIME_ALERT);
        return;
      }
      setValue(statusOptions.filter((value) => value.id === 2)[0].id);
      setStatusActual(2);
      handleSuccess();
      handleSuccessMessage(
        "La actualización del estatus del usuario se ha realizado con éxito."
      );
      //handleRefreshData();
      setTimeout(() => {
        handleSuccess();
        handleSuccessMessage("");
      }, TIME_ALERT);
    } catch (error) {
      handleError();
      if (error.message === "NETWORK_CONNECTION") {
        setErrorMessage(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
      } else {
        handleErrorMessage("Lo sentimos ocurrió un error, intente más tarde");
      }
      setValue(
        statusOptions.filter((value) => value.id === statusActual)[0].id
      );
      setTimeout(() => {
        handleError();
        handleErrorMessage("");
      }, TIME_ALERT);
    } finally {
      handleLoading();
      closeBlockUserModal();
    }
  };
  const activateUserAfterBlock = async () => {
    try {
      handleLoading();
      if (!navigator.onLine) {
        throw new Error("NETWORK_CONNECTION");
      }
      let response = await updateStatusUserById(id, 1);
      if (!response.success) {
        handleError();
        if (response.message === "NETWORK_CONNECTION") {
          setErrorMessage(
            "No hay conexión a Internet. Por favor, verifica tu conexión."
          );
        } else if (response.message) {
          setErrorMessage(response.message);
        } else {
          handleErrorMessage("Lo sentimos ocurrió un error, intente más tarde");
        }
        setValue(
          statusOptions.filter((value) => value.id === statusActual)[0].id
        );
        setTimeout(() => {
          handleError();
          handleErrorMessage("");
        }, TIME_ALERT);
        return;
      }
      setStatusActual(1);
      handleSuccess();
      handleSuccessMessage(
        "La actualización del estatus del usuario se ha realizado con éxito."
      );
      setValue(statusOptions.filter((value) => value.id === 1)[0].id);
      setTimeout(() => {
        handleSuccess();
        handleSuccessMessage("");
      }, TIME_ALERT);
    } catch (error) {
      handleError();
      if (error.message === "NETWORK_CONNECTION") {
        setErrorMessage(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
      } else {
        handleErrorMessage("Lo sentimos ocurrió un error, intente más tarde");
      }
      setValue(
        statusOptions.filter((value) => value.id === statusActual)[0].id
      );
      setTimeout(() => {
        handleError();
        handleErrorMessage("");
      }, TIME_ALERT);
    } finally {
      handleLoading();
    }
  };
  const handleActiveUser = () => {
    if (statusActual === 1) return;
    else if (statusActual === 2) {
      setActivateUser((prevState) => !prevState); //activateUserAfterBlock();
      setFromBlockActivater(true);
    } else {
      setActivateUser((prevState) => !prevState);
      setFromBlockActivater(false);
    }
  };
  const finishedActivateUser = () => {
    handleRefreshData(true);
  };

  const handleDeleteUser = useCallback(() => {
    if (statusActual !== 0)
      if (deleteButtonRef && deleteButtonRef?.current !== null) {
        deleteButtonRef.current.click();
      }
  }, [deleteButtonRef, statusActual]);
  const finishedDeleteUser = () => {
    handleRefreshData(true);
  };

  const availableActions = useMemo(
    () => ({
      0: handleDeleteUser,
      1: handleActiveUser,
      2: handleBlockUser,
    }),
    [statusActual, statusOptions]
  );

  const handleChange = (e) => {
    if (!navigator.onLine) {
      setError(true);
      setErrorMessage(
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    availableActions[e.value.toString()]();
  };

  return {
    value,
    statusOptionsLimited,
    handleChange,
    deleteButtonRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    activateUser,
    handleActiveUser,
    statusActual,
    finishedDeleteUser,
    finishedActivateUser,
    openBlockUser,
    bloquearUsuario,
    closeBlockUserModal,
    fromBlockActivate,
  };
};

export default function UserStatus({
  status,
  id,
  name,
  rolesUser,
  index,
  setDataTable = () => {},
}) {
  const {
    value,
    statusOptionsLimited,
    handleChange,
    deleteButtonRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    activateUser,
    statusActual,
    handleActiveUser,
    finishedDeleteUser,
    finishedActivateUser,
    openBlockUser,
    bloquearUsuario,
    closeBlockUserModal,
    fromBlockActivate,
  } = useUserTableStatus({
    status,
    id,
    setDataTable,
    index,
  });
  const windowWidth = useWindowWidth();
  return (
    <>
      <Select
        id={`user_table_status_${id}`}
        name={`user_table_status_${id}`}
        label=""
        value={value}
        handleSetValue={handleChange}
        options={statusOptionsLimited}
        optionsContainerHiddenOptional={statusOptionsLimited.filter(
          (val) => val.id === value
        )}
        hide_current={true}
        valueKey="id"
        labelKey="name"
        arrow="white"
        max_width={67}
        arrowWidth={5}
        {...(index >= 7 || windowWidth <= 768
          ? {
              direction: {
                bottom: "auto",
                left: "0",
                top: "0",
                transform: "translateY(-101%)",
              },
            }
          : {})}
        arrowPosition={{ right: "5px" }}
        customStyles={{
          color: "white",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0px 5px",
          lineHeight: "9px",
          maxHeight: "19px",
          borderRadius: "4px",
          minHeight: "19px",
          display: "flex",
          alignItems: "center",
          background:
            statusActual === 0
              ? "#767676"
              : statusActual === 1
              ? "#609571"
              : "#C43C42",
          border: `1px solid ${
            statusActual === 0
              ? "#767676"
              : statusActual === 1
              ? "#609571"
              : "#C43C42"
          } `,
        }}
      />
      {activateUser && (
        <ActivateUser
          id={id}
          handleActiveUser={handleActiveUser}
          finishedActivateUser={finishedActivateUser}
          fromBlock={fromBlockActivate}
          rolesActuales={rolesUser}
        />
      )}
      {
        <DeleteUser
          id={id}
          name={name}
          deleteButtonRef={deleteButtonRef}
          status={0}
          finishedDeleteUser={finishedDeleteUser}
        />
      }
      {openBlockUser && (
        <BloquearUsuario
          bloquearUsuario={bloquearUsuario}
          closeModal={closeBlockUserModal}
          name={name}
        />
      )}
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </>
  );
}
