import React from "react";

export default function UserProfilePhoto({ src }) {
  return (
    <img
      className="rounded rounded-circle border-secondary border-2 mr-auto"
      src={
        src === "data:image/*;base64,"
          ? "/images/complete_register/default_user.svg"
          : src
      }
      alt="User profile photo"
      width={"20px"}
      height={"20px"}
    />
  );
}
