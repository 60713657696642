import React, { useState, useEffect, useCallback } from 'react';
import ModalCardPagesSmall from '../Modal/ModalCardPagesSmall';
import { createBrowserHistory } from 'history';
import dayjs from 'dayjs';

const checkDays = {
  75: (
    <>
      Por seguridad de tu cuenta, tienes <b>15</b> días para actualizar tu contraseña,
      evita bloqueos en tu acceso.
    </>
  ),
  87: (
    <>
      Por seguridad de tu cuenta, tienes <b>3</b> días para actualizar tu
      contraseña, evita bloqueos en tu acceso.
    </>
  ),
  88: (
    <>
      Por seguridad de tu cuenta, tienes <b>2</b> días para actualizar tu
      contraseña, evita bloqueos en tu acceso.
    </>
  ),
  89: (
    <>
      Por seguridad de tu cuenta, tienes <b>1</b> día para actualizar tu
      contraseña, evita bloqueos en tu acceso.
    </>
  ),
  90: (
    <>
      Tu contraseña ha cumplido <b>90</b> días de vigencia, es momento de actualizarla
    </>
  ),
};

export default function VerificarPassword() {
  const [endOfTime, setEndOfTime] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const getMessageForDays = useCallback((days) => {
    return checkDays[days.toString()] ?? false
  }, []);

  const verificarVigencia = useCallback(() => {
    const lastPasswordDateChage = dayjs(sessionStorage.getItem("lastPasswordChangedDate"));
    const now = dayjs();
    const diffDays = now.diff(lastPasswordDateChage, 'days');
    const passMessage = getMessageForDays(diffDays);

    if (passMessage) {
      setAlert(true);
      setAlertMessage(passMessage);
      setEndOfTime(diffDays >= 90);
      return;
    }
  }, [getMessageForDays]);

  const history = createBrowserHistory();

  useEffect(() => {
    if (history)
      if (history.location)
        if (history.location.state)
          if (history.location.state.changePassword) return;

    verificarVigencia();
  }, []);


  const cambiarContrasenia = () => {
    history.replace('/ajustes', { changePassword: true });
    history.go();
  };

  const cancelar = () => {
    setAlert(false);
  };

  return (
    <>
      {alert && (
        <div
          className={`
        modal_container 
        position-fixed 
        w-100 
        top-0 
        start-0 
        overflow-x-hidden
        overflow-y-auto
        min-vh-100
      `}
        >
          <ModalCardPagesSmall className={'shadow-lg'}>
            <div
              className='overflow-x-hidden mx-auto notification-90-dias'
              style={{
                minWidth: '500px',
                alignItems: 'center',
              }}
            >
              <div
                className='row m-0'
                style={{
                  width: '60%',
                  height: '60%',
                  maxWidth: '271px',
                }}
              >
                <div class='d-flex flex-column h-100 justify-content-between' style={{ padding: "60px 0 60px 0"}}>
                  <p
                    className={'card_title mb-0'}
                    style={{}}
                  >
                    Actualización de <br />
                    contraseña requerida{' '}
                  </p>
                  <p
                    className={'card_text'}
                    style={{ marginTop: '18px', marginBottom: '47px' }}
                  >
                    {alertMessage}
                  </p>
                  <div className='d-flex gap-3'>
                    <button
                      className={`button button_active`}
                      style={{ maxWidth: 'fit-content' }}
                      type='button'
                      onClick={cambiarContrasenia}
                    >
                      Actualizar ahora
                    </button>
                    {!endOfTime && (
                      <button
                        className={'cancel_button'}
                        type='button'
                        onClick={cancelar}
                      >
                        Cancelar
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalCardPagesSmall>
        </div>
      )}
    </>
  );
}
