import React, {
  useState,
  useMemo,
  useEffect,
  createContext,
  useRef,
} from "react";
import Table from "../Table/Container";
import Loader from "../Loader/Loader";
import dayjs from "dayjs";
import {
  getManagementDashboard,
  getManagementDashboardExport,
} from "../../services/api";
import { Permisos } from "../../services/permisos.js";
import ExportManagement from "./ExportManagement";
import FilterForm from "./FilterForm";
import {
  formatIDSummary,
  useLoaderReducer,
  useErrorReducer,
  useSucceedReducer,
  useValuesReducer,
  formatDateToGMT06,
  toUtcTimeZone,
  validarFecha,
  getDefaultInitialDateValues,
} from "../../services/data";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import { pagination_size } from "../../services/enviromentConfig";
import utc from "dayjs/plugin/utc";
import usePaginationBack from "../Table/usePaginationBack";
import Pagination from "../Table/Pagination.jsx";
import { useCallback } from "react";
import LimpiarFiltros from "../Sharing/LimpiarFiltros.jsx";

dayjs.extend(utc);

export const UserContext = createContext();

const formatHour = (hour) => {
  if (hour < 0 || hour > 23) {
    //throw new Error('Hour must be between 0 and 23');
    return "23:59";
  }
  const formattedHour = hour.toString().padStart(2, "0");
  return `${formattedHour}:00`;
};

const INITIAL_VALUES = {
  search: "",
  status: "",
  register_date_range: new Date().toString(),
  register_hours_range: "",
  ...getDefaultInitialDateValues(),
  type_event: "0",
};

export const handleExportAllManagmentPDF = async ({
  values,
  service,
  loading,
  error,
}) => {
  try {
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    loading("increment");
    const response = await service(...values);
    if (!response.success) {
      loading("decrement");
      if (response.name === "AbortError") {
        console.log("Abort request");
      } else if (response?.message === "Sin registros") {
        console.error("NOT FOUND");
        return { success: false, error: new Error("NOT_FOUND") };
      } else {
        console.error("Error request");
        return {
          success: false,
          error: new Error(response?.message || "Error request"),
        };
      }
    }
    loading("decrement");
    return { success: true, data: response.data };
  } catch (err) {
    loading("decrement");
    if (err.message === "NETWORK_CONNECTION") {
      error("No hay conexión a Internet. Por favor, verifica tu conexión.");
      return;
    }
    error("Lo sentimos ocurrió un error, intente más tarde");
    console.error("Error request");
    console.error(err);
    return { success: false, error: err };
  }
};
export default function Body() {
  const { errorValues, handleError } = useErrorReducer();
  const { loaderValues, handleLoader } = useLoaderReducer();
  const { succeedValues, handleSucceed } = useSucceedReducer();
  const {
    createHandleChange,
    createHandleDatePickerChange,
    daysController,
    values,
  } = useValuesReducer(INITIAL_VALUES);
  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();
  const [tableData, setTableData] = useState([]);
  const dataTable = useMemo(() => tableData, [tableData]);
  const abortControllerRef = useRef(null);
  const [orderConfig, setOrderConfig] = useState({
    order: "asc",
    orderBy: "_id",
  });

  const handleOrderByColumns = useCallback(({ sort_key, order }) => {
    setOrderConfig({ order, orderBy: sort_key });
  }, []);

  const columns = [
    {
      name: "ID de carga",
      selector: ({ _id }) => formatIDSummary(_id),
      className: "table_header_cell",
      key_name: "_id",
      service: handleOrderByColumns,
      style: {
        width: "100%",
        minWidth: "154px",
        maxWidth: "154px",
      },
      cell_styles: {
        width: "100%",
        minWidth: "154px",
        maxWidth: "154px",
      },
      cell_classname: "table_row_cell",
    },
    {
      name: "Fecha/Hora de carga",
      selector: ({ fecha_alta }) =>
        (dayjs(formatDateToGMT06(fecha_alta)).format("YYYY-MM-DD HH:mm") ??
          "") + " (GMT-6)",
      className: "table_header_cell",
      key_name: "fecha_alta",
      service: handleOrderByColumns,
      style: {
        width: "100%",
        minWidth: "71px",
        maxWidth: "175px",
      },
      cell_styles: {
        width: "100%",
        minWidth: "71px",
        maxWidth: "175px",
      },
      cell_classname: "table_row_cell",
    },
    {
      name: "Total de registros",
      selector: ({ total_registros }) => total_registros,
      className: "table_header_cell",
      key_name: "total_registros",
      service: handleOrderByColumns,
      style: {
        width: "100%",
        minWidth: "51px",
        maxWidth: "182px",
      },
      cell_styles: {
        width: "100%",
        minWidth: "51px",
        maxWidth: "182px",
      },
      cell_classname: "table_row_cell",
    },
    {
      name: "Total de errores",
      selector: ({ total_errores }) => total_errores,
      className: "table_header_cell",
      key_name: "total_errores",
      service: handleOrderByColumns,
      style: {
        width: "100%",
        minWidth: "43px",
        maxWidth: "150px",
      },
      cell_styles: {
        width: "100%",
        minWidth: "43px",
        maxWidth: "150px",
      },
      cell_classname: "table_row_cell",
    },
    {
      name: "Total de duplicados",
      selector: ({ total_duplicados }) => total_duplicados,
      className: "table_header_cell",
      key_name: "total_duplicados",
      service: handleOrderByColumns,
      style: {
        width: "100%",
        minWidth: "53px",
        maxWidth: "160px",
      },
      cell_styles: {
        width: "100%",
        minWidth: "53px",
        maxWidth: "160px",
      },
      cell_classname: "table_row_cell",
    },
    {
      name: "Prom. índice calidad de registro",
      selector: ({ avgQualityScore }) => avgQualityScore,
      className: "table_header_cell",
      key_name: "avgQualityScore",
      service: handleOrderByColumns,
      style: {
        width: "100%",
        minWidth: "119px",
        maxWidth: "197px",
      },
      cell_styles: {
        width: "100%",
        minWidth: "119px",
        maxWidth: "197px",
      },
      cell_classname: "table_row_cell",
    },
  ];

  useEffect(() => {
    if (values.days !== "") {
      daysController[values.days]({
        start_date: "start_date",
        start_hours: "start_hours",
        end_date: "end_date",
        end_hours: "end_hours",
      });
    }
  }, [daysController, values.days]);

  const getData = async () => {
    let hasLoaderBeenDecremented = false;
    try {
      if (!navigator.onLine) {
        throw new Error("NETWORK_CONNECTION");
      }

      if (abortControllerRef?.current) {
        abortControllerRef?.current.abort();
      }

      abortControllerRef.current = new AbortController();

      if (values?.start_hours && values?.end_hours) {
        handleLoader("increment");

        let fecha_inicial = toUtcTimeZone(
          `${values.start_date} ${values.start_hours}`
        ).toISOString();
        let fecha_final = toUtcTimeZone(
          values.end_hours === "23:59"
            ? `${values.end_date} ${values.end_hours}:59`
            : `${values.end_date} ${values.end_hours}:00`
        ).toISOString();

        const response = await getManagementDashboard(
          fecha_inicial,
          fecha_final,
          values?.search,
          pagination.page,
          pagination_size,
          abortControllerRef.current,
          orderConfig.order,
          orderConfig.orderBy
        );

        if (response.name === "AbortError") {
          return;
        }

        if (!response.success) {
          handleError(response?.message);
          return;
        }

        setTableData(response.data ?? []);
        handleValidateTotalPages({
          totalPages: response.pagination.TotalPage,
          page: response.pagination.CurrentPage,
          totalElements: response.pagination.TotalCount,
        });
      }
    } catch (error) {
      if (error.message === "NETWORK_CONNECTION") {
        handleError(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
      } else if (error.name !== "AbortError") {
        handleError("Lo sentimos, ocurrió un error. Intente más tarde.");
        console.error(error);
      }
    } finally {
      if (!hasLoaderBeenDecremented) {
        handleLoader("decrement");
      }
    }
  };

  useEffect(() => {
    if (validarFecha(values, handleError)) getData();
    else setTableData([]);
  }, [values, orderConfig, pagination.page]);

  const handleChange = createHandleChange(handleResetPagination);

  const handleDatePickerChange = createHandleDatePickerChange(
    handleResetPagination
  );

  const formatDate = (date, hour) => {
    let fecha = new Date(date);
    const fechaMexico = new Date(`${fecha.toDateString()} ${hour}`);
    return fechaMexico.toISOString();
  };

  const handleExport = async () => {
    try {
      if (!navigator.onLine) {
        throw new Error("NETWORK_CONNECTION");
      }
      handleLoader("increment");

      let fecha_inicial = toUtcTimeZone(
        `${values.start_date} ${values.start_hours}`
      ).toISOString();
      let fecha_final = toUtcTimeZone(
        values.end_hours === "23:59"
          ? `${values.end_date} ${values.end_hours}:59`
          : `${values.end_date} ${values.end_hours}:00`
      ).toISOString();
      const response = await getManagementDashboard(
        fecha_inicial,
        fecha_final,
        values?.search,
        1,
        pagination.TotalCount
      );
      if (!response.success) {
        if (response.name === "AbortError") {
          return false;
        } else {
          handleLoader("decrement");
          return false;
        }
      }
      handleLoader("decrement");
      return response.data ?? [];
    } catch (error) {
      handleLoader("decrement");
      if (error.message === "NETWORK_CONNECTION") {
        handleError(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
      }
      handleError("Lo sentimos ocurrió un error, intente más tarde");
      console.error(error);
      return false;
    }
  };
  const handleExportBack = async (format = "pdf") => {
    let fecha_inicial = toUtcTimeZone(
      `${values.start_date} ${values.start_hours}`
    ).toISOString();
    let fecha_final = toUtcTimeZone(
      values.end_hours === "23:59"
        ? `${values.end_date} ${values.end_hours}:59`
        : `${values.end_date} ${values.end_hours}:00`
    ).toISOString();
    return await handleExportAllManagmentPDF({
      values: [
        fecha_inicial,
        fecha_final,
        values?.search,
        format,
        orderConfig.order,
        orderConfig.orderBy,
      ],
      loading: handleLoader,
      error: handleError,
      service: getManagementDashboardExport,
    });
  };

  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={dataTable}
        header_classname="table_header"
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
        header_styles={{
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [dataTable]);

  return (
    <UserContext.Provider
      value={{ handleExport, valuesFilter: values, handleExportBack }}
    >
      <div className="container-fluid d-flex flex-column p-0">
        <div
          style={{ marginBottom: "11px" }}
          className="d-flex justify-content-between justify-content-end mt-3 managenement_filter_contaianer"
        >
          {" "}
          {Permisos.verificarPermiso("Cargas BD", "Buscar") && (
            <FilterForm
              values={values}
              handleChange={handleChange}
              handleDatePickerChange={handleDatePickerChange}
            />
          )}
          {Permisos.verificarPermiso("Cargas BD", "Exportar") && (
            <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap">
              <LimpiarFiltros
                handleChange={handleChange}
                INITIAL_VALUES={INITIAL_VALUES}
              />
              <ExportManagement />
            </div>
          )}
        </div>
        {memoizedUserTable}
      </div>

      {loaderValues.loading > 0 && <Loader />}
      {errorValues.timeLeft > 0 && (
        <AlertaGeneral type={"error"}>{errorValues.currentError}</AlertaGeneral>
      )}
      {succeedValues.timeLeft > 0 && (
        <AlertaGeneral type={"success"}>
          {succeedValues.currentSucceed}
        </AlertaGeneral>
      )}
    </UserContext.Provider>
  );
}
