import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginComponent from "../components/Login/LoginComponent";
import LoginCard from "../components/Login/LoginCard";
import { useEnviroment } from "../services/enviromentConfig";
import { configurarVariablesEnviroment as configENV } from "../services/enviromentConfig";
const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");

  const { configurarEnviroment } = useEnviroment({
    controladorEnviromentObtenido: configENV,
  });
  useEffect(() => {
    configurarEnviroment(true);
  }, []);

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);

  const login = async () => {};

  return (
    <LoginCard>
      <LoginComponent />
    </LoginCard>
  );
};

export default Login;
