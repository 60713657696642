import React, { useEffect } from "react";
import Template from "../components/Nav/Template";
import DashboardComponent from "../components/Dashboard/DashboardComponent";
import { Permisos } from "../services/permisos";
import PageHeader from "../components/Pages/Header";
import PageTitle from "../components/Pages/Title";
import PageSubtitle from "../components/Pages/Subtitle";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  Permisos.refrescarPrivilegios();
  const navigate = useNavigate();
  const cerrarSesion = () => {
    sessionStorage.clear();
    Permisos.destroy();
    navigate("/login");
  };

  useEffect(() => {
    if (!Permisos.verificarPermiso("Dashboard", "Ver la sección"))
      cerrarSesion();
  }, []);
  return (
    <Template overflowX="hidden">
      <PageHeader>
        <PageTitle>Dashboard</PageTitle>
      </PageHeader>
      <DashboardComponent />
    </Template>
  );
};

export default Dashboard;
