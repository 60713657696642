import React, { useContext } from 'react';
import SearchInput from '../Inputs/SearchInput';
import Select from '../Inputs/Select';
import { ActivityContext } from './Body';
import { useTimeFilters } from '../Sharing/TimeFilters';
import SlideWrapper from '../Sharing/SlideWrapper';

const useActivityContext = () => useContext(ActivityContext);

export default function FilterForm({
  handleChange,
  handleDatePickerChange,
  values,
}) {
  const { eventLogsOptions } = useActivityContext();
  const timeFilters = useTimeFilters({
    handleDatePickerChange,
    handleChange,
    values,
  });

  return (
    <form
      className='container-fluid justify-content-start d-flex gap-1 transactrions_filter_form'
      style={{ padding: '0 5px 0 0' }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <SlideWrapper>
        <div style={{ maxWidth: '137px' }} className='container-fluid p-0 m-0'>
          <SearchInput
            onChange={(e) => {
              handleChange({
                name: e.target.name,
                value: e.target.value,
              });
            }}
            value={values.search}
            label='Buscar'
            id='search'
            name='search'
            type='search'
            valid={true}
            rounded={true}
          />
        </div>
        {timeFilters}
        <div style={{ maxWidth: '151px' }} className='container-fluid p-0 m-0 '>
          <Select
            id={`type_event`}
            name={`type_event`}
            label='Tipo de evento registrado'
            value={values.type_event}
            handleSetValue={({ field, value }) => {
              handleChange({
                name: field,
                value,
              });
            }}
            options={eventLogsOptions}
            valueKey='id'
            labelKey='level'
            rounded={true}
            max_width={151}
            arrowWidth={5}
          />
        </div>
      </SlideWrapper>
      <button type='submit' className='d-none'></button>
    </form>
  );
}
