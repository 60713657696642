import React, { useState } from "react";

const OptionInterruptor = ({
  title = "",
  subtitle = "",
  checked = false,
  onClick = () => {},
}) => {
  return (
    <>
      <div className="col-6 d-flex align-items-center first-column">
        <p className="fw-bold m-0" style={{ fontSize: "10px" }}>
          {title + " "}
          <span
            style={{
              fontSize: "8px",
              color: "var(--settings-description-color)",
              fontWeight: "700",
            }}
          >
            {subtitle}
          </span>
        </p>
      </div>
      <div className="col-2 d-flex align-items-center second-column">
        <div className="position-relative">
          <div className="checkbox-apple">
            <input
              className="yep"
              id="check-apple"
              type="checkbox"
              checked={checked}
              onClick={onClick}
              aria-label={title ?? ""}
            />
            <label htmlFor="check-apple"></label>
          </div>
        </div>
      </div>
    </>
  );
};

export default OptionInterruptor;
