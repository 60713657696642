import React, { useState } from "react";

const LoginCard = ({ children }) => {
  return (
    <div
      className={`vh-100 container-fluid d-flex justify-content-center p-3 align-items-center login-container-card`}
    >
      <div
        // className={`container-fluid bg-white d-flex flex-column justify-content-between p-3 p-md-4 p-lg-5 align-items-center login_card`}
        className={`container-fluid bg-white d-flex flex-column p-3 p-md-4 p-lg-5 align-items-center login_card`}
      >
        <img
          src={"/images/dicio/logo.webp"}
          alt="DICIO  Alliance logo"
          className={`login_logo`}
        />
        {children}
        <div className="d-flex flex-column justify-content-center align-items-center d-none">
          <p style={{ fontSize: "12px" }} className="fw-lighter m-0">
            ¿Necesitas ayuda?
          </p>
          {/*<a
            className={`fw-bold forgot_link`}
            target="_blank"
            href="https://wa.me/5579211062?text=Hola,%20necesito%20ayuda"
            rel="noreferrer"
          >
            Presiona aquí
          </a>*/}
        </div>
      </div>
    </div>
  );
};

export default LoginCard;
