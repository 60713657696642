import React, { useState, useReducer, useEffect, useContext } from "react";
import AssignRolesForm from "./AssignRolesForm";
import {
  activateUserService,
  getAllRolesService,
  deleteRolUserService,
  editUserService,
} from "../../services/api";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Loader from "../Loader/Loader";
import { UserContext } from "./Body";
import { TIME_ALERT } from "../../services/data";

const INITIAL_VALUES = {
  roleIds: [],
  userId: "",
};

const useUserContext = () => {
  try {
    const context = useContext(UserContext);
    if (!context) {
      throw new Error("useUserContext must be used within a UserProvider");
    }
    return context;
  } catch (error) {
    return {
      handleRefreshData: () => {
        console.warn(
          "handleRefreshData is not available because UserProvider is missing"
        );
      },
    };
  }
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      if (action.field === "roleIds") {
        const { roleIds } = state;
        if (roleIds.includes(action.value)) {
          return {
            ...state,
            roleIds: roleIds.filter((item) => item !== action.value),
          };
        } else {
          return {
            ...state,
            roleIds: [...roleIds, action.value],
          };
        }
      }
      return {
        ...state,
        [action.field]: action.value,
      };
    case "RESET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};
const handleActivateAfterBlockUser = async (values) => {
  try {
    const rolesDelete = values?.rolesActuales.filter(
      (elemento) => !values?.roleIds.includes(elemento?.id)
    );
    if (rolesDelete?.length > 0) {
      const rolesDeleteIds = rolesDelete.map((element) => element.id);
      const responseDeleteRoles = await deleteRolUserService({
        userId: values.userId,
        roleIds: rolesDeleteIds,
      });
      if (!responseDeleteRoles.success) {
        return {
          success: false,
          message: responseDeleteRoles?.message || "Error al activar usuario.",
        };
      }
    }
    const resposeEditUser = await editUserService({
      id: values?.userId,
      roleIds: values?.roleIds,
      estatus: 1,
    });
    if (!resposeEditUser?.success) {
      return {
        success: false,
        message: resposeEditUser?.message || "Error al activar usuario.",
      };
    }
    return {
      success: true,
      message: resposeEditUser?.message || "Usuario actualizado con éxito",
    };
  } catch (error) {
    return {
      success: false,
      message: error?.message || "Error al activar usuario.",
    };
  }
};
export const handleActivateUsers = async ({
  values,
  loading,
  error,
  error_message,
  success,
  success_message,
  service,
  handleActiveUser,
  finishedActivateUser,
}) => {
  try {
    loading(true);
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const response = await service(values);
    if (!response.success) {
      loading();
      error();
      error_message(response.message);
      setTimeout(() => {
        error();
        error_message("");
      }, TIME_ALERT);
      console.error("Error request");
      return;
    }
    loading();
    success();
    success_message(response.message);
    setTimeout(() => {
      success();
      success_message(response.message);
      //handleRefreshData();
      handleActiveUser();
      finishedActivateUser();
    }, TIME_ALERT);
  } catch (err) {
    loading();
    error();
    if (err.message === "NETWORK_CONNECTION") {
      error_message(
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
    } else {
      error_message("Lo sentimos ocurrió un error, intente más tarde");
    }
    setTimeout(() => {
      error();
      error_message("");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  }
};

export default function ActivateUser({
  id,
  handleActiveUser,
  finishedActivateUser,
  fromBlock,
  rolesActuales,
}) {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [rolCatalog, setRolCatalog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const { handleRefreshData } = useUserContext();

  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };
  const resetFieldValue = (field, value) => {
    dispatch({ type: "RESET_FIELD", field, value });
  };
  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleError = () => {
    setError((prevState) => !prevState);
  };

  const handleErrorMessage = (msj) => {
    setErrorMessage(msj);
  };

  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleSuccessMessage = (msj) => {
    setSuccessMessage(msj);
  };

  const handleCheckbox = (e) => {
    const { value, name } = e.target;
    setFieldValue("roleIds", name);
  };

  const handleResetRoleIds = () => {
    resetFieldValue("roleIds", []);
  };

  const handleRolCatalog = async () => {
    try {
      handleLoading();
      const response = await getAllRolesService();
      if (!response.success) {
        handleLoading();
        console.error(response.message);
        return;
      }
      handleLoading();
      setRolCatalog(response.data);
      return;
    } catch (error) {
      handleLoading();
      console.error("Error request");
      console.error(error);
      return;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleActivateUsers({
      values: { ...values, rolesActuales },
      loading: handleLoading,
      error: handleError,
      error_message: handleErrorMessage,
      success: handleSuccess,
      success_message: handleSuccessMessage,
      service: fromBlock ? handleActivateAfterBlockUser : activateUserService,
      handleActiveUser,
      handleRefreshData,
      finishedActivateUser,
    });
  };

  useEffect(() => {
    handleRolCatalog();
    setFieldValue("userId", id);
  }, []);
  useEffect(() => {
    for (const element of rolesActuales ?? []) {
      setFieldValue("roleIds", element.id);
    }
  }, [rolesActuales]);
  useEffect(() => {
    setIsValid(values?.roleIds.length > 0);
  }, [values]);

  return (
    <>
      <AssignRolesForm
        handleResetRoleIds={handleResetRoleIds}
        handleCheckbox={handleCheckbox}
        roles={rolCatalog}
        values={values.roleIds}
        onClick={handleSubmit}
        close={handleActiveUser}
      />
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </>
  );
}
