import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { REACT_APP_NAME_NODO } from "../../services/enviromentConfig";
export default function Title({ children }) {
  const [name, setName] = useState();
  useEffect(() => {
    setName(REACT_APP_NAME_NODO);
  }, [REACT_APP_NAME_NODO]);

  return (
    <h4 className={"main_title"}>
      {children}
      <span style={{ color: "#2D2D2D80", marginLeft: "5px" }}>
        {` / ${name}`}
      </span>
    </h4>
  );
}
