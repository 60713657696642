import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import writeXlsxFile from "write-excel-file";
import dayjs from "dayjs";
import csvDownload from "json-to-csv-export";
import { ActivityContext } from "./Body";
import Checkbox from "../Inputs/Checkbox";
import Modal from "../Modal/Modal";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Loader from "../Loader/Loader";
import { dowloadLinkFileBlob, TIME_ALERT } from "../../services/data";
const useActivityContext = () => useContext(ActivityContext);

const INITIAL_VALUES = {
  excel: "",
  csv: "",
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...INITIAL_VALUES,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const useExportToExcel = () => {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const { handleExport, handleExportBack, handleDownloadPdf } =
    useActivityContext();
  const modalRef = useRef(null);

  const handleModal = () => {
    modalRef?.current?.handleOpenModal();
  };
  const schema = [
    {
      column: "Usuario",
      type: String,
      value: (row) => row?.usuario ?? "",
    },
    {
      column: "Fecha/Hora",
      type: String,
      value: (row) =>
        dayjs
          .utc(row?.fechaHora)
          .utcOffset(-6)
          .format("DD/MM/YYYY HH:mm:ss [(GMT-6)]"),
    },
    {
      column: "Tipo de evento registrado",
      type: String,
      value: (row) => row?.tipoEvento ?? "",
    },
    {
      column: "Datos registrados",
      type: String,
      value: (row) => row.datosRegistrados,
    },
  ];

  const excelDownload = async () => {
    const data = await handleExport();
    const file = await writeXlsxFile(data, {
      schema,
      headerStyle: {
        backgroundColor: "#22135e",
        fontWeight: "bold",
        color: "#FFFFFF",
        align: "center",
      },
      fileName: "Bitácora.xls",
      sheet: "Bitácora",
    });
  };

  const csvDownloader = async () => {
    const data = await handleExport();
    const newData = data.map((props) => {
      const { logsLevel, ...rest } = props;
      return rest;
    });
    const dataToConvert = {
      data: newData,
      filename: "Bitácora",
      delimiter: ",",
      headers: [
        "Usuario",
        "Fecha/Hora",
        "Tipo de evento registrado",
        "Datos registrados",
      ],
    };
    csvDownload(dataToConvert);
  };

  const pdfDownloader = async () => {
    let res = await handleExportBack("pdf");
    if (!res?.success) {
      throw res?.error;
    }
    console.log(res.data);

    dowloadLinkFileBlob(res?.data, "Carga_Base_Datos.pdf");
  };

  const availableDownloads = {
    excel: excelDownload,
    csv: csvDownloader,
    pdf: handleDownloadPdf,
  };

  const handleDownload = async (type) => {
    try {
      if (!navigator.onLine) {
        throw new Error("NETWORK_CONNECTION");
      }
      await availableDownloads[type]();
      setSuccess(true);
      setSuccessMessage("La exportación de datos se ha realizado con éxito");
      handleModal();
    } catch (error) {
      if (error.message === "NETWORK_CONNECTION") {
        setError(true);
        setErrorMessage(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
        return;
      } else if (error.message === "") {
        return;
      } else {
        setError(true);
        setErrorMessage(error.message || "Ocurrió un error al exportar");
      }
    } finally {
      setTimeout(() => {
        setSuccess(false);
        setSuccessMessage("");
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const selected = Object.entries(values).reduce((acc, [key, value]) => {
        if (value === "true") {
          return key;
        }
        return acc;
      }, null);
      if (!selected) {
        setError(true);
        setErrorMessage("Por favor seleccione un formato de exportación");
        setTimeout(() => {
          setError(false);
          setErrorMessage("");
        }, TIME_ALERT);
        return;
      }
      handleDownload(selected);
    } catch (error) {
      if (error.message === "NETWORK_CONNECTION") {
        setErrorMessage(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
      } else if (error.message === "") {
      } else {
        setErrorMessage("Error al exportar");
      }
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
    }
  };
  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    setFieldValue(name, "true");
  };

  useEffect(() => {
    setIsValid(
      Object.entries(values).reduce((acc, [key, value]) => {
        if (value === "true") {
          return key;
        }
        return acc;
      }, null)
    );
  }, [values]);

  return {
    isValid,
    values,
    modalRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    handleDownload,
    handleCheckbox,
    handleSubmit,
    handleModal,
  };
};

export default function ExportData() {
  const {
    isValid,
    values,
    modalRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    handleCheckbox,
    handleSubmit,
    handleModal,
  } = useExportToExcel();
  return (
    <>
      <Modal ref={modalRef}>
        <div
          className={
            "user_export_data_container bg-white rounded position-absolute top-50 start-50 translate-middle modal_export_users"
          }
        >
          <div class="container-fluid d-flex flex-column p-0 justify-content-start align-items-start modal_container_title">
            <h4 class="main_title">Registro de eventos</h4>
            <p class="main_subtitle">
              Ve el registro de actividad del sistema en el nodo{" "}
            </p>
          </div>
          <div className="export_shadow_card container-fluid d-flex justify-content-center align-items-start p-0 flex-column">
            <p className="card_title" style={{ marginBottom: "25px" }}>
              Exportación de información
            </p>
            <p className="card_text">
              Se exportará la información seleccionada en los siguientes
              formatos:
            </p>
            <form onSubmit={handleSubmit} className="container p-0">
              <div className="container-fluid p-0 d-flex gap-3">
                <div className="">
                  <Checkbox
                    id="excel"
                    name="excel"
                    label="XLS"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.excel}
                  />
                </div>
                <div className="">
                  <Checkbox
                    id="csv"
                    name="csv"
                    label="CSV"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.csv}
                  />
                </div>
                <div className="">
                  <Checkbox
                    id="pdf"
                    name="pdf"
                    label="PDF"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.pdf}
                  />
                </div>
              </div>
              <div
                style={{ marginTop: "30px" }}
                className={"container-fluid p-0 d-flex gap-3"}
              >
                <button className={`button button_active`} type="submit">
                  Exportar
                </button>
                <button
                  type="button"
                  onClick={handleModal}
                  className="cancel_button"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <button
        type="button"
        onClick={handleModal}
        className="export_button d-flex gap-1 justify-content-center align-items-center"
      >
        Exportar
      </button>
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </>
  );
}
