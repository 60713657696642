import { getInfoUser } from "./api";
import { encryptStorage } from "./data";
export class Permisos {
  #permisosArray = [];
  datosCargados = false;
  cargandoDatos = false;
  email = "";
  intervalo = null;
  updateData = () => {};
  constructor() {
    Permisos.instance = this;
    this.cargandoDatos = true;
    this.email = sessionStorage.getItem("userEmail");
    if (sessionStorage.getItem("priviligesUser"))
      try {
        this.#permisosArray = JSON.parse(
          encryptStorage().decryptValue(sessionStorage.getItem("priviligesUser"))
        );
      } catch (error) {
        console.log(error);
      }
      
    this.#solicitarDatos();
    if (!this.intervalo)
      this.intervalo = setInterval(() => {
        this.#solicitarDatos();
      }, 60000);
    //   
  }
  set updateDataFunc(func) {
    this.updateData = func;
  }
  async #solicitarDatos() {
    try {
      console.info("Solicitando Privilegios");
      let res = await (
        await getInfoUser(sessionStorage.getItem("userEmail"))
      ).json();
      if (res.isSucceed) {
        let roles = res.data.roles;
        let permisos = this.#prepararArrayPermisos(roles);
        sessionStorage.setItem("priviligesUser", encryptStorage().encryptValue(JSON.stringify(permisos)));
        this.#permisosArray = permisos;
        this.updateData({ OK: true });
        this.datosCargados = true;
        return;
      } else {
        this.updateData({ OK: false, error: "NOTSUCCEED" });
        this.cargandoDatos = false;
        return;
      }
    } catch (error) {
      console.warn(error);
      Permisos.instance = false;
      this.updateData({ OK: false, error: error });
      return;
    }
  }
  #prepararArrayPermisos(data) {
    let salidaPriv = {};
    for (let i = 0; i < data.length; i++) {
      const rol = data[i];
      for (let j = 0; j < rol.privileges?.length; j++) {
        const privilege = rol.privileges[j];
        let vals = privilege.value?.split("-");
        if (vals.length === 2) {
          if (!salidaPriv[vals[0].trim()]) salidaPriv[vals[0].trim()] = {};
          salidaPriv[vals[0].trim()][vals[1].trim()] = true;
        }
      }
    }
    return salidaPriv;
  }
  static destroy() {
    if (Permisos.instance) {
      clearInterval(Permisos.instance.intervalo);
      Permisos.instance = null;
    }
  }
  static getInstance() {
    if (
      !Permisos.instance ||
      Permisos.instance.email !== sessionStorage.getItem("userEmail")
    ) {
      let inst = new Permisos();
      return inst;
    } else if (
      !Permisos.instance.cargandoDatos &&
      !Permisos.instance.datosCargados
    ) {
      Permisos.instance.#solicitarDatos();
      return Permisos.instance;
    } else {
      return Permisos.instance;
    }
  }
  permisoRevision(seccion, accion) {
    if (this.#permisosArray)
      if (this.#permisosArray[seccion])
        return this.#permisosArray[seccion][accion] ?? false;
      else return false;
  }

  obtenerPermisos(){
    this.#solicitarDatos();
  }

  modulosDisponibles() {
    return Object.keys(this.#permisosArray);
  }
  static refrescarPrivilegios() {
    let permiso = Permisos.getInstance();
    return permiso.obtenerPermisos();
  }
  static obtenerModulosDisponibles() {
    let permiso = Permisos.getInstance();
    return permiso.modulosDisponibles();
  }
  static verificarPermiso(seccion, accion) {
    let permiso = Permisos.getInstance();
    return permiso.permisoRevision(seccion.trim(), accion.trim());
  }
}
