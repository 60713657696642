import React, { useState } from "react";
import Input from "../Inputs/Input";
import isEmail from "validator/lib/isEmail";
import { forgotPassword } from "../../services/api";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { TIME_ALERT } from "../../services/data";

const FormCorreoRecuperar = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [finished, setFinished] = useState(false);
  const [descriptionMessage, setDescriptionMessage] = useState(
    "Por favor, captura tu correo electrónico para recuperar tu contraseña"
  );
  const [isValid, setIsValid] = useState(false);
  const [correo, setCorreo] = useState("");
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/login");
  };

  const handleChange = (e) => {
    setCorreo(e.target.value);
    setIsValid(isEmail(e.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) {
      setError(true);
      setErrorMessage("Por favor complete los campos");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    try {
      setLoading(true);
      if (!navigator.onLine) {
        throw new Error("NETWORK_CONNECTION");
      }
      const response = await forgotPassword(correo);
      if (!response.success) {
        setLoading(false);
        setError(true);
        setErrorMessage(response.message);
        if (response.email)
          setDescriptionMessage(
            "Lo sentimos, no encontramos ninguna cuenta con el correo que proporcionaste"
          );
        setTimeout(() => {
          setError(false);
          setErrorMessage("");
        }, TIME_ALERT);
        console.error("Error request");
        return;
      }
      setLoading(false);
      setSuccess(true);
      setSuccessMessage(response.message);
      setFinished(true);
      setDescriptionMessage(
        "Listo, te hemos enviado instrucciones para restablecer tu contraseña"
      );
      setTimeout(() => {
        setSuccess(false);
        setSuccessMessage("");
      }, TIME_ALERT);
    } catch (error) {
      setLoading(false);
      setError(true);
      if (error.message === "NETWORK_CONNECTION") {
        setErrorMessage(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
      } else {
        setErrorMessage("Lo sentimos ocurrió un error, intente más tarde");
      }
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
      console.error("Error request");
      console.error(error);
      return;
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={`p-0 overflow-hidden`}
        style={{ maxWidth: "279px", marginTop: "69px" }}
      >
        <div className="col p-0  d-flex flex-column ">
          <p className="fw-bold" style={{ marginBottom: "40px" }}>
            {descriptionMessage}
          </p>
          <div className="position-relative mt-0 container-fluid p-0">
            <Input
              onChange={handleChange}
              value={correo}
              label="Correo electrónico"
              id="email"
              name="email"
              type="email"
              valid={true}
              rounded={false}
            />
          </div>
          {!finished && (
            <div style={{ marginTop: "40px" }} className="d-flex gap-3">
              <button
                className={`button ${
                  isValid ? "button_active" : "button_disabled"
                }`}
                style={{ maxWidth: "fit-content" }}
                disabled={!isValid}
                type="submit"
              >
                Recuperar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="cancel_button"
              >
                Cancelar
              </button>
            </div>
          )}
          {finished && (
            <button
              className={`button button_active`}
              style={{ maxWidth: "fit-content", marginTop: "40px" }}
              type="button"
              onClick={handleCancel}
            >
              Regresar
            </button>
          )}
        </div>
      </form>
      {error && <AlertaGeneral type="error">{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type="success">{successMessage}</AlertaGeneral>
      )}
      {loading && <Loader />}
    </>
  );
};

export default FormCorreoRecuperar;
