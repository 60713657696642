import React, { useState } from "react";
import { useEffect } from "react";
import InputCodeTwoFactor from "../Login/InputCodeTwoFactor";
const FormTwoFactor = ({
  userName = "",
  activar = false,
  passwordMessage = false,
  reenviarCodigo = () => {},
  enviarCodigo = () => {},
  cancelar = () => {},
  setCodigo = () => {},
  codeValid,
}) => {
  return (
    <div
      className="overflow-x-hidden mx-auto modal-two-factor-container"
      // style={{
      //   minWidth: "600px",
      //   minHeight: "560px",
      //   alignItems: "center",
      // }}
    >
      <div
        className="row m-0"
        style={{
          // width: "60%",
          // height: "60%",
          maxWidth: "320px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <div className="d-flex flex-column h-100 justify-content-between modal_content_twofactor">
          <div className="card_title">
            <img
              src="/images/dicio/logo.webp"
              style={{ width: "100%" }}
              alt=""
            />
          </div>
          <p className="card_text">
            <b>{userName}</b>, hemos enviado un código a tu correo electrónico
            {passwordMessage ? (
              <>, para actualizar tu contraseña. </>
            ) : (
              <>
                {/* . Por favor, ingrésalo para {activar ? "activar" : "desactivar"}{" "}
                el doble factor de autenticación */}
                , para actualizar tu contraseña.
              </>
            )}
          </p>
          <InputCodeTwoFactor
            setCodeProps={setCodigo}
            validacionInput={codeValid}
          />
          <div className="d-flex gap-5 no_send_code">
            <p style={{ fontSize: "12px", fontWeight: "700" }}>
              ¿No recibiste ningún código?{" "}
              <a
                onClick={reenviarCodigo}
                style={{
                  color: "#1B80F2",
                  fontWeight: "700",
                  fontSize: "12px",
                }}
                href="#"
              >
                Reenviar{" "}
              </a>
            </p>
          </div>
          <div className="d-flex gap-5">
            <button
              type="submit"
              className="button button_active"
              onClick={enviarCodigo}
            >
              {passwordMessage
                ? "Ingresar"
                : activar
                ? "Activar"
                : "Desactivar"}
            </button>
            <button type="button" className="cancel_button" onClick={cancelar}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
      {/* <Help /> */}
    </div>
  );
};

export default FormTwoFactor;
