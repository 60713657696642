/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
// Librerías
import { useNavigate } from "react-router-dom";
import ReportePDF from "dicio-npm-reporte-pdf";
import html2canvas from "html2canvas";
// Funciones
import { Permisos } from "../../services/permisos";
import {
  useValuesReducer,
  getDefaultInitialDateValues,
} from "../../services/data";
// Componentes
import Loader from "../Loader/Loader";
import Statistics from "./Statistics";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import ModalExportDashboard from "./ModalExportDashboard";
import ResultValidationChart from "../Graficas/ResultValidationChart";
import GraficaTiempoPromedio from "../Graficas/GraficaTiempoPromedio";
import FilterDashboard from "./FilterDashboard";
import GraficaHistoricoNumeroTotalUnico from "../Graficas/GraficaHistoricoNumeroTotalUnico";
import { REACT_APP_NAME_NODO } from "../../services/enviromentConfig";
import { TIME_ALERT } from "../../services/data.js";

// Servicios
import {
  getStatisticsByDate,
  getStatisticsByDay,
  getStatisticsTimeAverage,
  getStatisticsValidation,
} from "../../services/api";
// Se movió el objeto de exportación para una mejor lectura de este archivo
import { objetoProvisional } from "./PdfExportObject";
import LimpiarFiltros from "../Sharing/LimpiarFiltros.jsx";

const INITIAL_VALUES = {
  ...getDefaultInitialDateValues(),
};

const INITIAL_STATISTICS = {
  registrosNodo: "0",
  transaccionesNodo: "0",
  no_cargas_total: "0",
  calidad_foto: "0",
  tiempoPromedio: "0",
  estatusNodo: "0",
  calidad_registro: "0",
};

const getError = (error) => {
  console.log(`🚀 ----------------------------🚀`);
  console.log(`🚀 ~ getError ~ error:`, error);
  console.log(`🚀 ----------------------------🚀`);
};

const getDashboardData = async ({ service, values, mapResponse }) => {
  try {
    const response = await service({
      start_date: values.start_date,
      start_hours: values.start_hours,
      end_date: values.end_date,
      end_hours: values.end_hours,
    });

    if (!response.success) throw new Error(response.message);

    if (response?.data?.Respuesta) throw new Error(response?.data?.Respuesta);

    return mapResponse(response?.data);
  } catch (error) {
    throw new Error(error.message || "Ocurrió un error inesperado");
  }
};

export default function DashboardComponent() {
  const {
    createHandleChange,
    createHandleDatePickerChange,
    daysController,
    values,
  } = useValuesReducer(INITIAL_VALUES);
  const [nameNodo, setNameNodo] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const contenedorScroll = useRef(null);
  const [objectIdsDivGraphic, setObjectIdsDivGraphic] = useState({});
  const [statisticsByDate, setStatisticsByDate] = useState(INITIAL_STATISTICS);
  const [scoreStatistics, setScoreStatistics] = useState("");
  const [statisticsTimeAverage, setStatisticsTimeAverage] = useState({});
  const [statisticsByDay, setStatisticsByDay] = useState({});
  const [propsPDF, setPropsPDF] = useState({});
  const navigate = useNavigate();
  const contenedorGraficas = useRef(null);
  const [padding, setPadding] = useState(0);

  // Manejador general de cambios creado mediante una HOF del hook useValuesReducer
  const handleChange = createHandleChange();

  // Manejador de calendario creado mediante una HOF del hook useValuesReducer
  const handleDatePickerChange = createHandleDatePickerChange();

  const loadData = useCallback(async () => {
    if (!navigator.onLine) {
      setError("No hay conexión a Internet. Por favor, verifica tu conexión.");
      setLoading(false);
      setTimeout(() => {
        setError(null);
      }, TIME_ALERT);
      return;
    }

    setLoading(true);

    const requests = [
      getDashboardData({
        service: getStatisticsByDate,

        values: {
          start_date: values.start_date,
          start_hours: values.start_hours,
          end_date: values.end_date,
          end_hours: values.end_hours,
        },
        mapResponse: (data) => ({
          registrosNodo: data?.noRegistros,
          transaccionesNodo:
            data?.peticionesTransacciones?.peticiones_validacion_identidad,

          no_cargas_total: data?.no_cargas_total?.toLocaleString() ?? "0",
          calidad_foto: data?.calidad_foto_validacion?.toLocaleString() ?? "0",
          tiempoPromedio: data?.tiempoPromedio ?? "0",
          estatusNodo: data?.estatusNodo,
          calidad_registro: data?.calificacionNodo,
        }),
      }),
      getDashboardData({
        service: getStatisticsValidation,
        values: {
          start_date: values.start_date,
          start_hours: values.start_hours,
          end_date: values.end_date,
          end_hours: values.end_hours,
        },
        mapResponse: (data) => ({
          validationChart: [
            data?.Coincidencias?.no_Encontrados?.Historicos ?? [],
            data?.Coincidencias?.no_coincidencias?.Historicos ?? [],
            data?.Coincidencias?.coincidencias?.Historicos ?? [],
          ],
        }),
      }),
      getDashboardData({
        service: getStatisticsTimeAverage,
        values: {
          start_date: values.start_date,
          start_hours: values.start_hours,
          end_date: values.end_date,
          end_hours: values.end_hours,
        },
        mapResponse: (data) => ({
          graficaTiempoPromedio: data?.TiempoPromedio?.Historico,
        }),
      }),
      getDashboardData({
        service: getStatisticsByDay,
        values: {
          start_date: values.start_date,
          start_hours: values.start_hours,
          end_date: values.end_date,
          end_hours: values.end_hours,
        },
        mapResponse: (data) => ({
          graficaHistoricoNumeroTotalUnico: data?.Transacciones?.Historico,
        }),
      }),
    ];
    const results = await Promise.allSettled(requests);

    results.forEach((result, index) => {
      if (result.status === "rejected") {
        setError(result.reason.message);
        setTimeout(() => setError(null), TIME_ALERT);
      }

      if (result.status === "fulfilled") {
        switch (index) {
          case 0:
            setStatisticsByDate(result.value);
            break;
          case 1:
            setScoreStatistics(result.value);
            break;
          case 2:
            setStatisticsTimeAverage(result.value);
            break;
          case 3:
            setStatisticsByDay(result.value);
            break;
          default:
            break;
        }
      } else {
        switch (index) {
          case 0:
            setStatisticsByDate({});
            break;
          case 1:
            setScoreStatistics({});
            break;
          case 2:
            setStatisticsTimeAverage({});
            break;
          case 3:
            setStatisticsByDay({});
            break;
          default:
            break;
        }
      }
    });

    setLoading(false);
  }, [values, setLoading, setError]);

  const redirigirTransacciones = () => {
    navigate("/transacciones");
  };

  const getIdDivGraphic = useCallback((id, type) => {
    setObjectIdsDivGraphic((object) => ({
      ...object,
      [type]: id,
    }));
  }, []);

  const getObjectDocument = (doc) => {
    if (!doc) {
      setError(
        "Ocurrió un error al momento de generar el reporte, por favor intenta nuevamente"
      );
      setTimeout(() => {
        setError(null);
      }, TIME_ALERT);
      return;
    }
    setSuccess("La exportación de datos se ha realizado con éxito.");
    doc.save(
      `Información dashboard_${nameNodo.toLowerCase().replaceAll(" ", "_")}.pdf`
    );
    setLoadingExport(false);
    setPropsPDF({});
  };

  const availablecharts = {
    historicoNumeroTotalUnico: objectIdsDivGraphic.historicoNumeroTotalUnico,
    tiempoPromedio: objectIdsDivGraphic.tiempoPromedio,
    resultValidNode: objectIdsDivGraphic.resultValidNode,
  };

  const getImageGraphic = async (id) => {
    const div = document.querySelector(`#${availablecharts[id]}`);
    const canvasPromise = html2canvas(div, { scale: 4 });
    const canvas = await canvasPromise;
    const imgData = canvas.toDataURL("image/png");
    return imgData;
  };

  const exportarGraficasImagen = async () => {
    try {
      if (!navigator.onLine) {
        setError(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
        return;
      }
      const objetoProps = {
        numberPages: 0,
        content: [],
      };
      const pdfObject = await objetoProvisional({
        node_name: nameNodo,
        start_date: `${values.start_date} ${
          values.start_hours !== "" ? values.start_hours : "00:00"
        }`,
        end_date: `${values.end_date} ${
          values.end_hours !== "" ? values.end_hours : "00:00"
        }`,
        node_records: statisticsByDate?.registrosNodo,
        transaction_records: statisticsByDate?.transaccionesNodo,
        load_records: statisticsByDate?.no_cargas_total,
        photo_quality: statisticsByDate?.calidad_foto,
        average_time: statisticsByDate?.tiempoPromedio,
        getImageGraphic,
      });
      objetoProps.content.push(pdfObject);
      objetoProps.numberPages = objetoProps.numberPages + 1;
      objetoProps.getObjectDocument = getObjectDocument;
      objetoProps.getError = getError;
      setPropsPDF(objetoProps);
    } catch (error) {
      console.warn(error);
    }
  };

  // Encargado de controlar los cambios establecidios de periodos de tiempo en el filtro
  useEffect(() => {
    if (values.days !== "") {
      //setLoading(true);
      daysController[values.days]({
        start_date: "start_date",
        start_hours: "start_hours",
        end_date: "end_date",
        end_hours: "end_hours",
      });
    }
  }, [daysController, values.days]);

  useEffect(() => {
    setNameNodo(REACT_APP_NAME_NODO);
  }, [REACT_APP_NAME_NODO]);

  useEffect(() => {
    loadData();
  }, [
    values.start_date,
    values.start_hours,
    values.end_date,
    values.end_hours,
  ]);

  useEffect(() => {
    window.addEventListener("resize", validateResolution);
    validateResolution();
  }, []);

  const validateResolution = () => {
    let width = window.innerWidth;
    if (width >= 1600) setPadding(105);
    else if (width >= 1400) setPadding(65);
    else {
      setPadding(5);
    }
  };

  return (
    <>
      <Statistics
        {...{
          transaccionesNodo: statisticsByDate?.transaccionesNodo,
          no_cargas_total: statisticsByDate?.no_cargas_total,
          calidad_foto: statisticsByDate?.calidad_foto,
          tiempoPromedio: statisticsByDate?.tiempoPromedio,
          registrosNodo: statisticsByDate?.registrosNodo,
        }}
      />
      <div className="d-flex flex-1 mt-2 mb-4 flex-row justify-content-between dco-dashboard-filtros-container container-fluid p-0">
        <div
          className="d-flex flex-row align-items-center mt-3"
          style={{ flex: 1 }}
        >
          <div
            className={"dco_graficas-subtitle mx-1"}
            style={{ fontSize: "10px" }}
          >
            Estatus del nodo
          </div>
          <div
            className="dco_graficas-estatus-tag"
            style={
              statisticsByDate?.estatusNodo === 1
                ? {}
                : { backgroundColor: "#a30312" }
            }
          >
            {statisticsByDate?.estatusNodo === 1 ? "Activo" : "Inactivo"}
          </div>
          <div
            className="d-flex"
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#05FF00",
              marginLeft: "19px",
              marginRight: "7px",
              border: "2px solid #04B400",
            }}
          ></div>
          <div
            className={"dco_graficas-subtitle"}
            style={{ fontSize: "8.83px" }}
          >
            Índice de calidad del nodo :{" "}
            {statisticsByDate?.calidad_registro ?? 0}
          </div>
        </div>
        <div
          className="d-flex flex-row align-items-center dco_gracicasDashboard-filtros-busqueda flex-wrap-reverse justify-content-end flex-lg-nowrap justify-content-lg-end"
          style={{ flex: 2 }}
        >
          {Permisos.verificarPermiso("Dashboard", "Buscar") && (
            <FilterDashboard
              {...{
                handleDatePickerChange,
                handleChange,
                values,
              }}
            />
          )}
          {Permisos.verificarPermiso("Dashboard", "Exportar") && (
            <div className="my-1 mt-3 mx-1 justify-content-end d-flex">
              <div style={{ marginRight: "10px" }}>
                <LimpiarFiltros
                  handleChange={handleChange}
                  INITIAL_VALUES={INITIAL_VALUES}
                />
              </div>
              <ModalExportDashboard
                exportarGraficasImagen={exportarGraficasImagen}
                setLoading={setLoadingExport}
              />
            </div>
          )}
        </div>
      </div>
      <div
        id="chart_container"
        ref={contenedorScroll}
        className="container-fluid d-flex dashboard_chart_container"
        style={{
          columnGap: "10px",
          rowGap: "10px",
          padding: "2px",
          overflowY: "scroll",
          overflowX: "hidden",
          flexFlow: "wrap",
        }}
      >
        <GraficaHistoricoNumeroTotalUnico
          widthGeneral="100%"
          heightGeneral="fit-content"
          size={{
            height: 219,
          }}
          paddingRight={padding}
          flexFlow="1 0 600px"
          titulo="Total de transacciones en el nodo: "
          resumenNumero={statisticsByDate?.transaccionesNodo ?? 0}
          data={statisticsByDay?.graficaHistoricoNumeroTotalUnico ?? {}}
          onOptionsClick={redirigirTransacciones}
          getIdGraphic={getIdDivGraphic}
        />
        <GraficaTiempoPromedio
          widthGeneral="100%"
          heightGeneral="fit-content"
          size={{
            height: 219,
          }}
          paddingRight={padding}
          flexFlow="1 0 600px"
          titulo="Promedio tiempo de respuesta del nodo: "
          resumenNumero={statisticsByDate?.tiempoPromedio ?? 0}
          data={statisticsTimeAverage?.graficaTiempoPromedio ?? {}}
          onOptionsClick={redirigirTransacciones}
          getIdDivGraphic={getIdDivGraphic}
        />
        <ResultValidationChart
          data={scoreStatistics?.validationChart ?? []}
          widthGeneral="100%"
          heightGeneral="fit-content"
          size={{
            height: 219,
            width: window.width * 0.65,
          }}
          paddingRight={padding}
          flexFlow="1 0 600px"
          getIdDivGraphic={getIdDivGraphic}
        />
      </div>
      {error && <AlertaGeneral type={"error"}>{error}</AlertaGeneral>}
      <div ref={contenedorGraficas} style={{ display: "none" }}></div>
      {(loading || loadingExport) && <Loader />}

      {Object.keys(propsPDF).length > 0 && <ReportePDF {...propsPDF} />}
    </>
  );
}
