import React, { useRef, useState } from "react";
import EditForm from "./EditForm";
import Modal from "../Modal/Modal";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import { TIME_ALERT } from "../../services/data";
export default function EditUser({
  id,
  type = "edit",
  button_styles,
  children,
  disabled = false,
}) {
  const [error, setError] = useState("");
  const modalRef = useRef(null);
  const handleModal = () => {
    if (disabled) {
      setError("No es posible editar un usuario Inactivo");
      setTimeout(() => {
        setError("");
      }, TIME_ALERT);
      return;
    }
    modalRef?.current?.handleOpenModal();
  };
  return (
    <>
      <Modal ref={modalRef}>
        <EditForm
          type={type}
          idUser={id}
          modalRef={modalRef}
          handleModal={handleModal}
        />
      </Modal>
      <button
        className={`${button_styles ? button_styles : "edit_user_button"}`}
        type="button"
        style={{ maxWidth: "53px" }}
        onClick={handleModal}
      >
        {children}
      </button>
      {error && <AlertaGeneral type={"error"}>{error}</AlertaGeneral>}
    </>
  );
}
