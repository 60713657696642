import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const useTooltip = () => {
  const [focused, setFocused] = useState(false);
  const containerRef = useRef(null);
  const [position, setPosition] = useState({ left: 0, top: 0 });

  const handleFocus = () => {
    setFocused((prevState) => !prevState);
  };

  const updatePosition = () => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      setPosition({
        left: containerRect.left + 26,
        top: containerRect.top + containerRect.height + 2,
      });
    }
  };

  useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);
    window.addEventListener("scroll", updatePosition);
    window.addEventListener("hashchange", updatePosition);
    window.addEventListener("wheel", updatePosition);
    window.addEventListener("orientationchange", updatePosition);
    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, [focused]);

  return {
    focused,
    handleFocus,
    containerRef,
    position,
  };
};

const Tooltip = ({ position, children }) => {
  return createPortal(
    <div className="search_input_tooltip" style={{ ...position }}>
      {children}
    </div>,
    document.getElementById("root")
  );
};

export default function SearchInput({
  onChange,
  value,
  label,
  id,
  name,
  type,
  valid,
  rounded,
  disabled,
  max_width,
  tooltip_text = "Buscar: texto",
}) {
  const { focused, handleFocus, containerRef, position } = useTooltip();
  const inputError = !valid && value !== "";

  return (
    <>
      <div
        ref={containerRef}
        className="position-relative mt-3 container-fluid p-0"
      >
        <div
          style={{ ...(max_width ? { maxWidth: `${max_width}px` } : {}) }}
          className={`position-absolute pe-none 
          ${rounded ? "input_label_small" : "input_label"}
        ${
          !(focused || value !== "")
            ? "input_label_inside_input_search"
            : "input_label_outside_input_search"
        }
        ${disabled ? "opacity-50" : ""}
        `}
        >
          {label}
        </div>
        <input
          autoComplete="off"
          id={id}
          name={name}
          type={type}
          onFocus={handleFocus}
          onBlur={handleFocus}
          onChange={onChange}
          value={value}
          disabled={disabled}
          className={`input_search bg-transparent rounded-pill
          ${inputError ? `border-danger` : ""} 
          ${disabled ? "opacity-50" : ""}`}
          aria-label="Campo de busqueda"
        />
        <img
          src={"/images/icons/search.webp"}
          className="input_search_icon"
          width={16}
          height={17}
          alt="icono buscar"
        />
      </div>
      {focused && <Tooltip position={position}>{tooltip_text}</Tooltip>}
    </>
  );
}
