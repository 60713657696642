import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import writeXlsxFile from "write-excel-file";
import dayjs from "dayjs";
import csvDownload from "json-to-csv-export";
import { UserContext } from "./Body";
import Checkbox from "../Inputs/Checkbox";
import Modal from "../Modal/Modal";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Loader from "../Loader/Loader";
import { dowloadLinkFileBlob } from "../../services/data";
import { TIME_ALERT } from "../../services/data.js";

const useUserContext = () => useContext(UserContext);

const INITIAL_VALUES = {
  excel: "",
  csv: "",
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...INITIAL_VALUES,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const useExportToExcel = () => {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const { statusOptions, handleExport, valuesFilter, handleExportBack } =
    useUserContext();
  const modalRef = useRef(null);

  const handleModal = () => {
    modalRef?.current?.handleOpenModal();
  };

  const obtenerEncabezadoReporte = () => {
    return `Fecha y hora del reporte: ${new Date().toLocaleDateString()} ${new Date()
      .toTimeString()
      .substring(0, 8)} (GMT-6) Desde:  ${new Date(
      valuesFilter.date_init
    ).toLocaleDateString()} ${
      valuesFilter.hour_init?.hora
    } hrs - Hasta: ${new Date(valuesFilter.date_final).toLocaleDateString()} ${
      valuesFilter.hour_final?.hora
    }`;
  };
  const prepararDatosExcel = (data) => {
    let newData = [
      [
        {
          value: obtenerEncabezadoReporte(),
          type: String,
        },
      ],
    ];
    //Header
    const headerStyle = {
      backgroundColor: "#2D5F7F",
      fontWeight: "bold",
      color: "#FFFFFF",
      align: "center",
    };
    newData.push([
      {
        value: "ID_Carga",
        type: String,
        ...headerStyle,
      },
      {
        value: "FechayHora_Carga",
        type: String,
        ...headerStyle,
      },
      {
        value: "Total de registros",
        type: String,
        ...headerStyle,
      },
      {
        value: "Promedio indice calidad de registro",
        type: String,
        ...headerStyle,
      },
      {
        value: "Total errores",
        type: String,
        ...headerStyle,
      },
      {
        value: "Total duplicados",
        type: String,
        ...headerStyle,
      },
    ]);
    //Body
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      newData.push([
        {
          type: String,
          value: element._id.toString(),
        },
        {
          type: String,
          value: element.fecha_alta.toString(),
        },
        {
          type: Number,
          value: element.total_registros,
        },
        {
          type: Number,
          value: element.avgQualityScore,
        },
        {
          type: Number,
          value: element.total_errores,
        },
        {
          type: Number,
          value: element.total_duplicados,
        },
      ]);
    }
    return newData;
  };
  const excelDownload = async () => {
    // const data = await handleExport();

    // const file = await writeXlsxFile(prepararDatosExcel(data), {
    //   /*schema,
    //   headerStyle: {
    //     backgroundColor: "#2D5F7F",
    //     fontWeight: "bold",
    //     color: "#FFFFFF",
    //     align: "center",
    //   },*/
    //   fileName:
    //     "Estatus de carga de base de datos /" +
    //     process.env.REACT_APP_NAME_NODO +
    //     ".xlsx",
    //   sheet: "Estatus de carga BD",
    // });
    let res = await handleExportBack("xlsx");
    if (!res?.success) {
      throw res?.error;
    }
    dowloadLinkFileBlob(res?.data, "Carga_Base_Datos.xlsx");
  };

  const csvDownloader = async () => {
    /*const data = await handleExport();
    let newData = [];
    //Headers
    newData.push([
      "ID_Carga",
      "FechayHora_Carga",
      "Total de registros",
      "Promedio indice calidad de registro",
      "Total errores",
      "Total duplicados",
    ]);
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      newData.push([
        element._id,
        element.fecha_alta,
        element.total_registros,
        element.avgQualityScore,
        element.total_errores,
        element.total_duplicados,
      ]);
    }
    const dataToConvert = {
      data: newData,
      filename: "Estatus de carga BD",
      delimiter: ",",
      headers: [obtenerEncabezadoReporte()],
    };

    csvDownload(dataToConvert);*/
    let res = await handleExportBack("csv");
    if (!res?.success) {
      throw res?.error;
    }
    dowloadLinkFileBlob(res?.data, "Carga_Base_Datos.csv");
  };
  const pdfDownloader = async () => {
    let res = await handleExportBack("pdf");
    if (!res?.success) {
      throw res?.error;
    }
    console.log(res.data);

    dowloadLinkFileBlob(res?.data, "Carga_Base_Datos.pdf");
  };
  const availableDownloads = {
    excel: excelDownload,
    csv: csvDownloader,
    pdf: pdfDownloader,
  };

  const handleDownload = async (type) => {
    try {
      setLoading(true);
      if (!navigator.onLine) {
        throw new Error("NETWORK_CONNECTION");
      }
      await availableDownloads[type]();
      setSuccess(true);
      setSuccessMessage("La exportación de datos se ha realizado con éxito");
      setTimeout(() => {
        setSuccess(false);
        setSuccessMessage("");
        handleModal();
      }, TIME_ALERT);
    } catch (error) {
      console.log(error);
      setError(true);
      if (error?.message === "NOT_FOUND") {
        setErrorMessage("No hay contenido que exportar");
      } else if (error?.message === "NETWORK_CONNECTION") {
        setErrorMessage(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
      } else if (error?.message?.includes("máximo")) {
        setErrorMessage(error?.message);
      } else setErrorMessage("Ocurrió un error al exportar");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selected = Object.entries(values).reduce((acc, [key, value]) => {
      if (value === "true") {
        return key;
      }
      return acc;
    }, null);
    if (!selected) {
      setError(true);
      setErrorMessage("Por favor seleccione un formato de exportación");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    handleDownload(selected);
  };
  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    setFieldValue(name, "true");
  };

  useEffect(() => {
    setIsValid(
      Object.entries(values).reduce((acc, [key, value]) => {
        if (value === "true") {
          return key;
        }
        return acc;
      }, null)
    );
  }, [values]);

  return {
    isValid,
    values,
    modalRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    handleDownload,
    handleCheckbox,
    handleSubmit,
    handleModal,
  };
};

export default function ExportData() {
  const {
    isValid,
    values,
    modalRef,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    handleCheckbox,
    handleSubmit,
    handleModal,
  } = useExportToExcel();
  return (
    <>
      <Modal ref={modalRef}>
        <div
          className={
            "user_export_data_container bg-white rounded position-absolute top-50 start-50 translate-middle modal_export_gestion"
          }
        >
          <div class="container-fluid d-flex flex-column p-0 justify-content-start align-items-start modal_container_title">
            <h4 class="main_title">Estatus de carga de base de datos</h4>
            <p class="main_subtitle">
              Ve y descarga el estatus de las cargas de bases de datos
            </p>
          </div>
          <div className="export_shadow_card container-fluid d-flex justify-content-center align-items-start p-0 flex-column">
            <p className="card_title" style={{ marginBottom: "25px" }}>
              Exportación de información
            </p>
            <p className="card_text">
              Se exportará la información seleccionada en los siguientes
              formatos:
            </p>
            <form onSubmit={handleSubmit} className="container p-0">
              <div className="container-fluid p-0 d-flex gap-3">
                <div className="">
                  <Checkbox
                    id="excel"
                    name="excel"
                    label="XLS"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.excel}
                  />
                </div>
                <div className="">
                  <Checkbox
                    id="csv"
                    name="csv"
                    label="CSV"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.csv}
                  />
                </div>
                <div className="">
                  <Checkbox
                    id="pdf"
                    name="pdf"
                    label="PDF"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.pdf}
                  />
                </div>
              </div>
              <div
                style={{ marginTop: "30px" }}
                className={"container-fluid p-0 d-flex gap-3"}
              >
                <button className={`button button_active`} type="submit">
                  Exportar
                </button>
                <button
                  type="button"
                  onClick={handleModal}
                  className="cancel_button"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <button
        type="button"
        onClick={handleModal}
        className="export_button d-flex gap-1 justify-content-center align-items-center"
      >
        Exportar
      </button>
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </>
  );
}
