import React, { useContext, useReducer, useRef } from "react";
/* Componentes */
import Checkbox from "../Inputs/Checkbox";
import Modal from "../Modal/Modal";
/* Context */
import { TransaccionContext } from "./Body";

const useTransaccionContext = () => useContext(TransaccionContext);

const INITIAL_VALUES = {
  excel: "",
  csv: "",
  pdf: "",
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...INITIAL_VALUES,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const useExportToExcel = () => {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const modalRef = useRef(null);
  const { handleError, handleDownloadData } = useTransaccionContext();

  const handleModal = () => {
    modalRef?.current?.handleOpenModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selected = Object.entries(values).reduce((acc, [key, value]) => {
      if (value === "true") {
        return key;
      }
      return acc;
    }, null);
    if (!selected) {
      handleError("Por favor seleccione un formato de exportación")
      return;
    }
    handleDownloadData(selected, handleModal);
  };
  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    setFieldValue(name, "true");
  };

  return {
    values,
    modalRef,
    handleCheckbox,
    handleSubmit,
    handleModal,
  };
};

export default function ExportData() {
  const { values, modalRef, handleCheckbox, handleSubmit, handleModal } =
    useExportToExcel();

  return (
    <>
      <Modal ref={modalRef}>
        <div
          className={
            "user_export_data_container bg-white rounded position-absolute top-50 start-50 translate-middle modal_export_transactions"
          }
        >
          <div class="container-fluid d-flex flex-column p-0 justify-content-start align-items-start modal_container_title">
            <h4 class="main_title">Estatus de transacciones</h4>
            <p class="main_subtitle">
              Ve el estatus actual de las transacciones
            </p>
          </div>
          <div className="export_shadow_card container-fluid d-flex justify-content-center align-items-start p-0 flex-column">
            <p className="card_title" style={{ marginBottom: "25px" }}>
              Exportación de información
            </p>
            <p className="card_text">
              Se exportará la información seleccionada en los siguientes
              formatos:
            </p>
            <form onSubmit={handleSubmit} className="container p-0">
              <div className="container-fluid p-0 d-flex gap-3">
                <div className="">
                  <Checkbox
                    id="excel"
                    name="excel"
                    label="XLS"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.excel}
                  />
                </div>
                <div className="">
                  <Checkbox
                    id="csv"
                    name="csv"
                    label="CSV"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.csv}
                  />
                </div>
                <div className="">
                  <Checkbox
                    id="pdf"
                    name="pdf"
                    label="PDF"
                    rounded={true}
                    onChange={handleCheckbox}
                    value={values.pdf}
                  />
                </div>
              </div>
              <div
                style={{ marginTop: "30px" }}
                className={"container-fluid p-0 d-flex gap-3"}
              >
                <button className={`button button_active`} type="submit">
                  Exportar
                </button>
                <button
                  type="button"
                  onClick={handleModal}
                  className="cancel_button"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <button
        type="button"
        onClick={handleModal}
        className="export_button d-flex gap-1 justify-content-center align-items-center"
      >
        Exportar
      </button>
    </>
  );
}
