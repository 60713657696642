export class SingletonAPI {
    solicitudesActuales = {};
    constructor(){
        SingletonAPI.instance = this;
    }
    static getInstance() {
        if (
          !SingletonAPI.instance 
        ) {
          let inst = new SingletonAPI();
          return inst;
        } 
        else {
          return SingletonAPI.instance;
        }
    }
    static async realizarSolicitud (url, options, refreshExpire=500){
        let inst
    try {
       inst = this.getInstance();
       if(!inst.solicitudesActuales[url]){
        inst.solicitudesActuales[url] = fetch(url, options);
        inst.solicitudesActuales[url].catch(()=>{
            
        });        
        let res = await inst.solicitudesActuales[url];
        setTimeout(()=>{inst.solicitudesActuales[url]=false},refreshExpire)
        
        return (await(res)).clone();
       }
       else{
            return (await (inst.solicitudesActuales[url])).clone();
       }
    } 
    catch (error) {
        inst.solicitudesActuales[url]=false
        throw error;
    }
    }
}