import { configEndpoints } from "./api";

export let enviroment = window.enviromentConfig || process.env;
export let pagination_size = enviroment?.REACT_APP_CONSTANT_PAGINATION_SIZE;
export let REACT_APP_NAME_NODO = enviroment?.REACT_APP_CONSTANT_NAME_NODO;
export let REACT_APP_TOKEN_SECURITY_PASS = enviroment?.REACT_APP_TOKEN_SECURITY_PASS;
export const useEnviroment = ({controladorEnviromentObtenido})=>{
    const configurarEnviroment= async (forzarPeticion=true)=>{
        if(sessionStorage.getItem("enviromentConfig")){
            window.enviromentConfig = JSON.parse(sessionStorage.getItem("enviromentConfig"));
            controladorEnviromentObtenido()
        }
        if(!window.enviromentConfig || forzarPeticion){
            if(await realizarpeticionarchivoconfig(`${window.location.origin}/public/config.json`)){
                console.log("petición public");
            }
            else{
                await realizarpeticionarchivoconfig(`${window.location.origin}/config.json`);
                console.log("Peticion raiz");
            }
            
        }
        else{
            controladorEnviromentObtenido();
            sessionStorage.setItem("enviromentConfig",JSON.stringify(window.enviromentConfig))
        }
    }
    const realizarpeticionarchivoconfig = async (url)=>{
        try {
            let res = await  fetch(url);
            let data = await res.json();
            if(data){
                let funSal = data;
                window.enviromentConfig = funSal;
                sessionStorage.setItem("enviromentConfig",JSON.stringify(funSal))
                controladorEnviromentObtenido();
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error al obtener los datos de entorno:', error);
            return false;
        }
    }
    
    return{configurarEnviroment};
}

export const configurarVariablesEnviroment = () => {
    enviroment = window.enviromentConfig || process.env;
    configEndpoints();
    pagination_size = enviroment.REACT_APP_CONSTANT_PAGINATION_SIZE;
    REACT_APP_NAME_NODO = enviroment.REACT_APP_CONSTANT_NAME_NODO;
    REACT_APP_TOKEN_SECURITY_PASS = enviroment.REACT_APP_TOKEN_SECURITY_PASS;
    return{enviroment, pagination_size, REACT_APP_NAME_NODO, REACT_APP_TOKEN_SECURITY_PASS}
}
export const iniciarEnviroment = ()=>{
    if(sessionStorage.getItem("enviromentConfig")){
        window.enviromentConfig = JSON.parse(sessionStorage.getItem("enviromentConfig"));
    }
    configurarVariablesEnviroment();
}


