import { useMemo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import useSelect from "./useSelect";
import InputLabel from "./InputLabel";
import Tooltip from "./Tooltip";
const useRequired = ({ required = false, regex = false, value = "" }) => {
  const [messageTooltip, setMessageTooltip] = useState("");
  const [showTooltip, setShowTooltip] = useState("");
  const [validInput, setValidInput] = useState(true);
  const [valorInput, setValorInput] = useState("");
  const focusedStart = useRef(false);
  useEffect(() => {
    if (value) {
      setValorInput(value);
    }
  }, []);
  useEffect(() => {
    if (!required) return;
    if (valorInput && !value) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
    }
  }, [value]);
  const handleFocusRequired = (focused) => {
    if (!required) return;
    if (!focusedStart.current && focused) {
      focusedStart.current = true;
    } else if (!valorInput && focusedStart.current && !focused) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
    }
  };
  const handleChangeValue = (value) => {
    if (!required) return;
    const valorInputCurrent = value;
    setValorInput(valorInputCurrent);
    if (regex?.length > 0) {
      for (const element of regex) {
        let reg = new RegExp(element.value);
        if (
          element.coincidencia
            ? !reg.test(valorInputCurrent)
            : reg.test(valorInputCurrent)
        ) {
          setShowTooltip(true);
          setMessageTooltip(element.message);
          setValidInput(false);
          return;
        }
      }
    }
    if (!value) {
      setShowTooltip(true);
      setValidInput(false);
      setMessageTooltip("Campo vacío requerido");
      return;
    }
    setShowTooltip(false);
    setValidInput(true);
    setMessageTooltip("");
  };

  return {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  };
};
const OptionContainer = ({
  targetRef,
  position,
  options,
  valueKey,
  handleChange,
  labelKey,
  customStyles,
  ...props
}) => {
  return createPortal(
    <div
      ref={targetRef}
      className={`${
        props.rounded || customStyles
          ? "input_select_option_container_status"
          : "input_select_option_container_status"
      } shadow`}
      style={position}
    >
      {options.map((obj, index) => (
        <div
          key={`${obj[valueKey]}-${index}`}
          className={`d-flex justify-content-start ${
            props.rounded ? "input_select_option_small" : "input_select_option"
          } z-3`}
          onClick={() => handleChange(obj[valueKey])}
        >
          {obj[labelKey]}
        </div>
      ))}
    </div>,
    document.getElementById("root")
  );
};

const availableArrows = {
  dark: "/images/dicio/arrow_down.webp",
  white: "/images/icons/arrow_down_white.png",
};

export default function Select({
  error,
  value,
  handleSetValue,
  touched,
  id,
  label,
  options,
  valueKey,
  labelKey,
  customStyles,
  arrow = "dark",
  arrowWidth = 10,
  arrowPosition = {},
  max_width,
  hide_current = false,
  icon = null,
  defaultOption = true,
  direction = {
    bottom: "0",
    left: "0",
    transform: "translateY(101%)",
  },
  required = false,
  ...props
}) {
  const {
    isOpen,
    handleChange,
    handleClick,
    selectedOption,
    containerRef,
    targetRef,
    position,
    selectedLabelRef,
    labelRef,
    labelWidth,
  } = useSelect({
    handleSetValue,
    options,
    valueKey,
    id,
    value,
  });
  const {
    messageTooltip,
    showTooltip,
    validInput,
    handleFocusRequired,
    handleChangeValue,
  } = useRequired({ required, value });

  const handleChangeGeneral = (params) => {
    handleChange(params);
    handleChangeValue(params);
  };

  useEffect(() => {
    handleFocusRequired(isOpen);
  }, [isOpen, handleFocusRequired]);

  const inputError = !!(value !== "" && error);
  const inputValid = !!(value !== "" && !error);
  const labelProps = {
    focused: value !== "",
    inputError,
    inputValid,
    rounded: props.rounded,
    labelRef,
  };

  const filteredOptions = useMemo(() => {
    return hide_current
      ? defaultOption
        ? [
            { [valueKey]: "", [labelKey]: "Seleccione una opción" },
            ...options,
          ].filter((val) => val.id !== value)
        : options.filter((val) => val.id !== value)
      : defaultOption
      ? [{ [valueKey]: "", [labelKey]: "Seleccione una opción" }, ...options]
      : options;
  }, [hide_current, defaultOption, options, value, valueKey, labelKey]);

  return (
    <div
      ref={containerRef}
      className={`p-0 ${customStyles ? `${customStyles} mt-3` : "mt-3"} ${
        options.length === 0 && "pe-none opacity-50"
      } position-relative`}
      style={{ marginLeft: "0", width: "fit-content" }}
    >
      {label !== "" && <InputLabel {...labelProps}>{label}</InputLabel>}
      <div
        className={`input_select d-flex justify-content-start position-relative ${
          props.rounded ? "input_select_small rounded-pill" : "input_select"
        }  border ${
          inputError
            ? `border-danger`
            : props.rounded
            ? "border_for_small_inputs"
            : `border-black`
        } `}
        onClick={handleClick}
        style={{}}
      >
        <span
          ref={selectedLabelRef}
          className="label_value_select"
          style={{
            color: "inherit",
            fontSize: "inherit",
            fontWeight: "inherit",
            lineHeight: "inherit",
            opacity:
              selectedOption && selectedOption[labelKey]
                ? "1"
                : label
                ? "0"
                : "1",
          }}
        >
          {selectedOption && selectedOption[labelKey]
            ? selectedOption[labelKey]
            : label !== ""
            ? label
            : "Seleccione una opción"}
        </span>
        {icon ? (
          icon
        ) : (
          <img
            src={availableArrows[arrow]}
            width={arrowWidth}
            height={"auto"}
            className={`${
              props.rounded ? "input_select_icon_small" : "input_select_icon"
            } ${
              isOpen
                ? props.rounded
                  ? "input_select_icon_open_small"
                  : "input_select_icon_open"
                : ""
            } align-self-center`}
            alt="Select icon arrow"
            style={{
              cursor: "pointer",
              width: `${arrowWidth}px`,
              ...arrowPosition,
            }}
            onClick={handleClick}
          />
        )}
      </div>

      {isOpen && (
        <OptionContainer
          targetRef={targetRef}
          position={position}
          options={filteredOptions}
          valueKey={valueKey}
          handleChange={handleChangeGeneral}
          labelKey={labelKey}
          customStyles={customStyles}
          {...props}
        />
      )}
      {showTooltip && (
        <Tooltip valid={validInput} messageIncorrect={messageTooltip} />
      )}
    </div>
  );
}
